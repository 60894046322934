/* src/components/Service.css */
.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center the content vertically */
}

.service-item {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  align-items: center; /* Center items horizontally within each row */
}

.image-container,
.text-container {
  flex: 1; /* Each container takes up equal space */
  padding: 20px;
}

.image-container img {
  width: 100%;
  height: auto;
}

.text-container button {
  margin-right: 10px; /* Adjust the margin between button and paragraph */
}

.reverse {
  flex-direction: row-reverse; /* Opposite of .service-item */
}
  
  /* Mobile Responsive Styles */
@media (max-width: 768px) {
  .service-item {
    margin-bottom: 20px;
    flex-direction: column;
  }

  .service-item-reverse {
    margin-bottom: 20px;
  }

  .text-container button {
    margin-right: 0; /* Remove margin for better spacing */
  }

  .reverse {
    flex-direction: column; /* Change to column layout for mobile */
  }
}