/* src/components/Footer.css */
.footer-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
  justify-content: space-around;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 20px;
}

.footer-column {
  text-align: center;
  margin-bottom: 20px; /* Add some space between columns */
  flex: 1; /* Each column takes up equal space */
  max-width: 300px; /* Set a maximum width for the columns */
}

.footer-column h3 {
  margin-bottom: 10px;
}

.footer-column p {
  margin: 0;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .footer-column {
    flex: 1 0 100%; /* Columns take full width on smaller screens */
    max-width: none; /* Remove the maximum width on smaller screens */
  }
}