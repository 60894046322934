/* src/components/Header.css */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #703BE7;
  color: white;
}

.company-menu-container {
  display: flex;
  align-items: center;
}

.company-name {
  margin: 0;
  width: 250px;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-icon span {
  display: block;
  height: 3px;
  width: 25px;
  background-color: #333;
  margin: 5px 0;
}

nav {
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  li {
    margin-right: 20px; /* Adjust as needed */
  }

  a {
    text-decoration: none;
    color: #333; /* Add your desired text color */
    font-weight: bold;
  }
}

header.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Adjust z-index as needed */
  background-color: #ffffff; /* Change background color for fixed header */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow for a subtle effect */
}

/* Updated Responsive Styles */
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    margin-left: 78px; /* Adjust margin for better positioning */
  }

  nav {
    display: none;
    position: absolute;
    top: 70px;
    left: 10px; /* Adjust left position for better positioning */
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 5px;
  }

  nav.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align dropdown items vertically */
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 10px; /* Add space between dropdown items */
  }

  /* Styles for the fixed header */
  header.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* Adjust z-index as needed */
    background-color: #ffffff; /* Change background color for fixed header */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add shadow for a subtle effect */
  }
}