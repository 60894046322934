/* src/components/Hero.css */
.hero-container {
  height: 100vh; /* Set the height to 100% of the viewport height */
  width: 100%; /* Set the width to 100% of the viewport width */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure no overflow content is visible outside the hero section */
}

.hero-content {
  text-align: center;
  padding: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  width: 100%; /* Set the width to 100% to cover the full viewport width */
  height: 100%;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .hero-content {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  h1 {
    font-size: 24px; /* Adjust font size for smaller screens */
  }

  p {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}